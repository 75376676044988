@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'ag-grid-enterprise/styles/ag-grid.css';
@import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.input-is-invalid {
    @apply border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500
}

.primary-btn {
    @apply inline-flex justify-center px-2.5 py-1.5 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-uniper hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
}

.disabled-btn {
    @apply inline-flex justify-center px-2.5 py-1.5 border border-transparent shadow-sm text-base font-medium rounded-md bg-blue-400 opacity-75 text-white focus:outline-none 
}

.secondary-btn {
    @apply inline-flex items-center px-2.5 py-1.5 border border-transparent text-base font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
}

.secondary-btn:disabled {
    @apply opacity-50 cursor-not-allowed bg-gray-300 text-gray-700 hover:bg-gray-300 focus:ring-0;
  }

.cancel-btn {
    @apply bg-white px-2.5 py-1.5 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
}

.ng-select.input-error, ng-autocomplete.input-error {
    @apply pr-0
}
 
.ng-select.input-error .ng-select-container {
    @apply border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-900 focus:border-red-900   
}
 
.ng-select.input-error.ng-select-focused .ng-select-container {
    @apply border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-900 focus:border-red-900   
}
 
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{
    @apply outline-none ring-1 ring-blue-500 border-blue-500 !important;
}
 
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
    @apply outline-none ring-1 ring-blue-500 border-blue-500 !important;
}
 
.ng-select.ng-select-disabled>.ng-select-container {
   @apply opacity-75 bg-gray-100 text-gray-600 !important;
}

.rmvd-date-picker .mat-calendar-body-selected {
    @apply bg-uniper 
}

.rmvd-date-picker .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    @apply bg-uniper text-white
}

.ng-autocomplete .autocomplete-container .input-container input,  .autocomplete-container .input-container input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border border-gray-300 rounded-md read-only:bg-gray-100
}

.autocomplete-container .input-container input {
    border: solid 1px rgb(209 213 219) !important;
}

.ng-autocomplete-input-error {
    @apply border border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-900 focus:border-red-900 rounded-md
}

.ng-autocomplete .autocomplete-container {
    @apply shadow-none
}